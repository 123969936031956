
import './App.css';
import Allroutes from './Component/Allroutes';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Importing the bundled JS file


function App() {
  return (
    <div className="App">
      <Allroutes/>
    </div>
  );
}

export default App;
