import React from 'react'
import './Navbar.css'; 

const CottonBag = () => {
  return (
    <div>
   <div className="containter-fluid ">
      <div className="row">
        <div className="col-md-12 u1"></div>  
     
        <div className="col-md-12 u2"> <h1 className='text-6xl'>Cotton Bag</h1></div>
      
      </div>

     </div>
      </div>
  )
}

export default CottonBag