// Action types for customer address
export const ADD_CUSTOMER_ADDRESS_REQUEST = 'ADD_CUSTOMER_ADDRESS_REQUEST';
export const ADD_CUSTOMER_ADDRESS_SUCCESS = 'ADD_CUSTOMER_ADDRESS_SUCCESS';
export const ADD_CUSTOMER_ADDRESS_FAILURE = 'ADD_CUSTOMER_ADDRESS_FAILURE';


// Action Types
export const GET_CUSTOMER_ADDRESSES_REQUEST = 'GET_CUSTOMER_ADDRESSES_REQUEST';
export const GET_CUSTOMER_ADDRESSES_SUCCESS = 'GET_CUSTOMER_ADDRESSES_SUCCESS';
export const GET_CUSTOMER_ADDRESSES_FAILURE = 'GET_CUSTOMER_ADDRESSES_FAILURE';


export const ADD_BILLING_ADDRESSES_REQUEST = 'ADD_BILLING_ADDRESSES_REQUEST';
export const ADD_BILLING_ADDRESSES_SUCCESS = 'ADD_BILLING_ADDRESSES_SUCCESS';
export const ADD_BILLING_ADDRESSES_FAILURE = 'ADD_BILLING_ADDRESSES_FAILURE';
 