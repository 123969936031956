export const GET_SAREES_REQUEST = 'GET_SAREES_REQUEST';
export const GET_SAREES_SUCCESS = ' GET_SAREES_SUCCESS';
export const GET_SAREES_FAILURE = 'GET_SAREES_FAILURE';

export const GET_SAREES_BY_ID_REQUEST = 'GET_SAREES_BY_ID_REQUEST';
export const GET_SAREES_BY_ID_SUCCESS = ' GET_SAREES_BY_ID_SUCCESS';
export const GET_SAREES_BY_ID_FAILURE = 'GET_SAREES_BY_ID_FAILURE';

export const  DELETE_SAREE_CART_REQUEST = 'DELETE_SAREE_CART_REQUEST';
export const  DELETE_SAREE_CART_SUCCESS = 'DELETE_SAREE_CART_SUCCESS';
export const  DELETE_SAREE_CART_FAILURE = 'DELETE_SAREE_CART_FAILURE';