// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul>li>a{
    text-decoration: none;
    color: black;
}
span>a{
    text-decoration: none;
    font-weight: bold;
}

    @media (max-width: 768px) {
      .ty1, .ty3{
        flex: 0 0 100%; /* Last column takes full width */
        max-width: 100%;
        padding: 50px;
        text-align: center;

      }
      .ty2{
        flex: 0 0 50%; /* Next two columns take half width each */
        max-width: 50%;
      }

}
`, "",{"version":3,"sources":["webpack://./src/Component/Footer.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,YAAY;AAChB;AACA;IACI,qBAAqB;IACrB,iBAAiB;AACrB;;IAEI;MACE;QACE,cAAc,EAAE,iCAAiC;QACjD,eAAe;QACf,aAAa;QACb,kBAAkB;;MAEpB;MACA;QACE,aAAa,EAAE,0CAA0C;QACzD,cAAc;MAChB;;AAEN","sourcesContent":["ul>li>a{\r\n    text-decoration: none;\r\n    color: black;\r\n}\r\nspan>a{\r\n    text-decoration: none;\r\n    font-weight: bold;\r\n}\r\n\r\n    @media (max-width: 768px) {\r\n      .ty1, .ty3{\r\n        flex: 0 0 100%; /* Last column takes full width */\r\n        max-width: 100%;\r\n        padding: 50px;\r\n        text-align: center;\r\n\r\n      }\r\n      .ty2{\r\n        flex: 0 0 50%; /* Next two columns take half width each */\r\n        max-width: 50%;\r\n      }\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
